import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import store from '@/store';

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export default function checkPermission(value) {
  if (value && value !== '') {
    var roles = store.getters && store.getters.roles;
    var routeCodes = store.getters && store.getters.routeCodes;
    var hasPermission = routeCodes.includes(value) || roles.includes('admin') || roles.includes('系统管理员');
    // console.log(hasPermission)
    if (hasPermission) {
      return true;
    } else {
      return false;
    }
  }
}