import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import waves from '@/directive/waves'; // waves directive
import Pagination from '@/components/Pagination';
import { batchList, confirmUpload, distributorOverview, getLastUploadBatch as _getLastUploadBatch } from '@/api/portal';
import { getLocalTimestamp } from '@/utils';
import { getToken } from '@/utils/auth';
import request from '@/utils/request';
export default {
  name: 'Index',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      BASE_API: process.env.VUE_APP_BASE_API,
      myHeaders: {
        'X-Auth-Token': getToken()
      },
      tableName: 't_client_portal_upload_batch',
      tableKey: 0,
      rowKey: 'c_id',
      listData: [],
      listQuery: {
        page: 1,
        size: 10,
        language: this.$store.getters.language,
        param: {}
      },
      selectedData: [],
      total: 0,
      listLoading: true,
      distributorName: '',
      uploadDialogVisible: false,
      isFirstTimeUpload: 0,
      uploadType: 1,
      uploadOpenSwitch: 0,
      openStartDate: '',
      openEndDate: '',
      billStartDate: '',
      billEndDate: '',
      periodUploadOpenSwitch: 0,
      periodOpenStartDate: '',
      periodOpenEndDate: '',
      periodBillStartDate: '',
      periodBillEndDate: '',
      periodId: 0,
      uploadScheduleId: 0,
      uploadStatus: 0,
      uploadDataForm: {
        uploadType: 1,
        hasPurchaseData: 0,
        purchaseUploadFilePath: null,
        purchaseUploadFileName: null,
        hasSalesData: 0,
        salesUploadFilePath: null,
        salesUploadFileName: null,
        hasChangeData: 0,
        changeUploadFilePath: null,
        changeUploadFileName: null,
        hasStockData: 0,
        stockUploadFilePath: null,
        stockUploadFileName: null
      },
      purchaseUploadFileLoading: false,
      salesUploadFileLoading: false,
      stockUploadFileLoading: false,
      changeUploadFileLoading: false
    };
  },
  computed: {
    changeFileList: function changeFileList() {
      var list = [];
      var changeUploadFilePath = this.uploadDataForm.changeUploadFilePath;
      var changeUploadFileName = this.uploadDataForm.changeUploadFileName;
      if (changeUploadFilePath && changeUploadFileName) {
        list.push({
          name: changeUploadFileName,
          url: changeUploadFileName
        });
      }
      return list;
    },
    stockFileList: function stockFileList() {
      var list = [];
      var stockUploadFilePath = this.uploadDataForm.stockUploadFilePath;
      var stockUploadFileName = this.uploadDataForm.stockUploadFileName;
      if (stockUploadFilePath && stockUploadFileName) {
        list.push({
          name: stockUploadFileName,
          url: stockUploadFileName
        });
      }
      return list;
    },
    salesFileList: function salesFileList() {
      var list = [];
      var salesUploadFilePath = this.uploadDataForm.salesUploadFilePath;
      var salesUploadFileName = this.uploadDataForm.salesUploadFileName;
      if (salesUploadFilePath && salesUploadFileName) {
        list.push({
          name: salesUploadFileName,
          url: salesUploadFileName
        });
      }
      return list;
    },
    purchaseFileList: function purchaseFileList() {
      var list = [];
      var purchaseUploadFilePath = this.uploadDataForm.purchaseUploadFilePath;
      var purchaseUploadFileName = this.uploadDataForm.purchaseUploadFileName;
      if (purchaseUploadFilePath && purchaseUploadFileName) {
        list.push({
          name: purchaseUploadFileName,
          url: purchaseUploadFileName
        });
      }
      return list;
    },
    salesRequest: function salesRequest() {
      return {
        data: JSON.stringify({
          name: '销售数据'
        })
      };
    },
    stockRequest: function stockRequest() {
      return {
        data: JSON.stringify({
          name: '出入库数据数据'
        })
      };
    },
    changeRequest: function changeRequest() {
      return {
        data: JSON.stringify({
          name: '其他出入库数据'
        })
      };
    },
    purchaseRequest: function purchaseRequest() {
      return {
        data: JSON.stringify({
          name: '进货数据'
        })
      };
    },
    action: function action() {
      return this.BASE_API + 'client-portal-upload-batch/upload-file';
    },
    tableColumns: function tableColumns() {
      var _this = this;
      var tdds = this.$store.getters.tableDataDictionary.tables;
      if (tdds) {
        var currentTable = tdds.find(function (value) {
          return value.name === _this.tableName;
        });
        return currentTable.tableColumns;
      } else {
        return null;
      }
    }
  },
  created: function created() {
    this.getDistributorOverview();
    this.getBatchListData();
  },
  methods: {
    downloadPurchaseErrorFile: function downloadPurchaseErrorFile(index, item) {
      this.$set(item, '__downloadPurchaseErrorFileLoading', true);
      var data = {
        file_path: item.b_purchase_error_file_path,
        file_name: item.b_purchase_upload_file_name
      };
      var __callBack = function __callBack() {
        this.$set(item, '__downloadPurchaseErrorFileLoading', false);
      };
      var __onDownloadProgress = function __onDownloadProgress(__progress) {
        this.$set(item, '__downloadPurchaseErrorFileProgress', __progress);
      };
      this.getDownloadFileDetail(index, data, __callBack, __onDownloadProgress);
    },
    downloadPurchaseFile: function downloadPurchaseFile(index, item) {
      this.$set(item, '__downloadPurchaseFileLoading', true);
      var data = {
        file_path: item.b_purchase_upload_file_path,
        file_name: item.b_purchase_upload_file_name
      };
      var __callBack = function __callBack() {
        this.$set(item, '__downloadPurchaseFileLoading', false);
      };
      var __onDownloadProgress = function __onDownloadProgress(__progress) {
        this.$set(item, '__downloadPurchaseFileProgress', __progress);
      };
      this.getDownloadFileDetail(index, data, __callBack, __onDownloadProgress);
    },
    downloadSalesErrorFile: function downloadSalesErrorFile(index, item) {
      this.$set(item, '__downloadSalesErrorFileLoading', true);
      var data = {
        file_path: item.b_sales_error_file_path,
        file_name: item.b_sales_upload_file_name
      };
      var __callBack = function __callBack() {
        this.$set(item, '__downloadSalesErrorFileLoading', false);
      };
      var __onDownloadProgress = function __onDownloadProgress(__progress) {
        this.$set(item, '__downloadSalesErrorFileProgress', __progress);
      };
      this.getDownloadFileDetail(index, data, __callBack, __onDownloadProgress);
    },
    downloadSalesFile: function downloadSalesFile(index, item) {
      this.$set(item, '__downloadSalesFileLoading', true);
      var data = {
        file_path: item.b_sales_upload_file_path,
        file_name: item.b_sales_upload_file_name
      };
      var __callBack = function __callBack() {
        this.$set(item, '__downloadSalesFileLoading', false);
      };
      var __onDownloadProgress = function __onDownloadProgress(__progress) {
        this.$set(item, '__downloadSalesFileProgress', __progress);
      };
      this.getDownloadFileDetail(index, data, __callBack, __onDownloadProgress);
    },
    downloadChangeErrorFile: function downloadChangeErrorFile(index, item) {
      this.$set(item, '__downloadChangeErrorFileLoading', true);
      var data = {
        file_path: item.b_change_error_file_path,
        file_name: item.b_change_upload_file_name
      };
      var __callBack = function __callBack() {
        this.$set(item, '__downloadChangeErrorFileLoading', false);
      };
      var __onDownloadProgress = function __onDownloadProgress(__progress) {
        this.$set(item, '__downloadChangeErrorFileProgress', __progress);
      };
      this.getDownloadFileDetail(index, data, __callBack, __onDownloadProgress);
    },
    downloadChangeFile: function downloadChangeFile(index, item) {
      this.$set(item, '__downloadChangeFileLoading', true);
      var data = {
        file_path: item.b_change_upload_file_path,
        file_name: item.b_change_upload_file_name
      };
      var __callBack = function __callBack() {
        this.$set(item, '__downloadChangeFileLoading', false);
      };
      var __onDownloadProgress = function __onDownloadProgress(__progress) {
        this.$set(item, '__downloadChangeFileProgress', __progress);
      };
      this.getDownloadFileDetail(index, data, __callBack, __onDownloadProgress);
    },
    downloadStockErrorFile: function downloadStockErrorFile(index, item) {
      this.$set(item, '__downloadStockErrorFileLoading', true);
      var data = {
        file_path: item.b_stock_error_file_path,
        file_name: item.b_stock_upload_file_name
      };
      var __callBack = function __callBack() {
        this.$set(item, '__downloadStockErrorFileLoading', false);
      };
      var __onDownloadProgress = function __onDownloadProgress(__progress) {
        this.$set(item, '__downloadStockErrorFileProgress', __progress);
      };
      this.getDownloadFileDetail(index, data, __callBack, __onDownloadProgress);
    },
    downloadStockFile: function downloadStockFile(index, item) {
      this.$set(item, '__downloadStockFileLoading', true);
      var data = {
        file_path: item.b_stock_upload_file_path,
        file_name: item.b_stock_upload_file_name
      };
      var __callBack = function __callBack() {
        this.$set(item, '__downloadStockFileLoading', false);
      };
      var __onDownloadProgress = function __onDownloadProgress(__progress) {
        this.$set(item, '__downloadStockFileProgress', __progress);
      };
      this.getDownloadFileDetail(index, data, __callBack, __onDownloadProgress);
    },
    getDownloadFileDetail: function getDownloadFileDetail(index, item, __callBack, __onDownloadProgress) {
      var _this2 = this;
      request({
        url: 'client-portal-upload-batch/download-file-detail',
        method: 'post',
        data: item
      }).then(function (response) {
        if (response.data.code === '0') {
          _this2.downloadFile(response.data.data.file_size, index, item, __callBack, __onDownloadProgress);
        } else {
          _this2.$notify({
            title: _this2.$t('notification.error.title'),
            message: response.data.message,
            type: 'error',
            duration: 3000
          });
        }
      });
    },
    downloadFile: function downloadFile(total, index, item, __callBack, __onDownloadProgress) {
      var __this = this;
      this.$set(item, 'loading', true);
      this.$set(item, 'showProgress', true);
      request({
        url: 'client-portal-upload-batch/download-file',
        method: 'post',
        data: item,
        headers: {
          'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/octet-stream'
        },
        responseType: 'arraybuffer',
        callBack: function callBack() {
          try {
            return __callBack.bind(__this)();
          } catch (e) {}
        },
        onDownloadProgress: function onDownloadProgress(progressEvent) {
          var loaded = progressEvent.loaded;
          var __progress = Math.round(loaded * 100 / total);
          if (typeof __onDownloadProgress == 'function') {
            __onDownloadProgress.bind(__this)(__progress);
          }
        },
        attachmentFileName: item.file_name
      }).then(function (res) {});
    },
    beforeSalesFileUpload: function beforeSalesFileUpload(file) {
      this.salesUploadFileLoading = true;
      console.log(file);
    },
    beforeStockFileUpload: function beforeStockFileUpload(file) {
      this.stockUploadFileLoading = true;
      console.log(file);
    },
    beforeChangeFileUpload: function beforeChangeFileUpload(file) {
      this.changeUploadFileLoading = true;
      console.log(file);
    },
    beforePurchaseFileUpload: function beforePurchaseFileUpload(file) {
      this.purchaseUploadFileLoading = true;
      console.log(file);
    },
    handleSalesFileRemove: function handleSalesFileRemove(file, fileList) {
      console.log(file, fileList);
      this.uploadDataForm.salesUploadFilePath = null;
      this.uploadDataForm.salesUploadFileName = null;
    },
    handleStockFileRemove: function handleStockFileRemove(file, fileList) {
      console.log(file, fileList);
      this.uploadDataForm.stockUploadFilePath = null;
      this.uploadDataForm.stockUploadFileName = null;
    },
    handleChangeFileRemove: function handleChangeFileRemove(file, fileList) {
      console.log(file, fileList);
      this.uploadDataForm.changeUploadFilePath = null;
      this.uploadDataForm.changeUploadFileName = null;
    },
    handlePurchaseFileRemove: function handlePurchaseFileRemove(file, fileList) {
      console.log(file, fileList);
      this.uploadDataForm.purchaseUploadFilePath = null;
      this.uploadDataForm.purchaseUploadFileName = null;
    },
    handleSalesFileUploadPreview: function handleSalesFileUploadPreview(file) {
      console.log(file);
    },
    handleStockFileUploadPreview: function handleStockFileUploadPreview(file) {
      console.log(file);
    },
    handleChangeFileUploadPreview: function handleChangeFileUploadPreview(file) {
      console.log(file);
    },
    handlePurchaseFileUploadPreview: function handlePurchaseFileUploadPreview(file) {
      console.log(file);
    },
    onSalesFileUploadError: function onSalesFileUploadError(err, file, fileList) {
      this.salesUploadFileLoading = false;
      this.uploadDataForm.salesUploadFilePath = null;
      this.uploadDataForm.salesUploadFileName = null;
    },
    onStockFileUploadError: function onStockFileUploadError(err, file, fileList) {
      this.stockUploadFileLoading = false;
      this.uploadDataForm.stockUploadFilePath = null;
      this.uploadDataForm.stockUploadFileName = null;
    },
    onChangeFileUploadError: function onChangeFileUploadError(err, file, fileList) {
      this.changeUploadFileLoading = false;
      this.uploadDataForm.changeUploadFilePath = null;
      this.uploadDataForm.changeUploadFileName = null;
    },
    onPurchaseFileUploadError: function onPurchaseFileUploadError(err, file, fileList) {
      this.purchaseUploadFileLoading = false;
      this.uploadDataForm.purchaseUploadFilePath = null;
      this.uploadDataForm.purchaseUploadFileName = null;
    },
    onSalesFileUploadSuccess: function onSalesFileUploadSuccess(response, file, fileList) {
      this.salesUploadFileLoading = false;
      console.log('-----onSalesFileUploadSuccess-----');
      console.log(arguments);
      if (response.code != '0') {
        var message = response.message;
        if (isNull(message)) {
          message = response;
        }
        this.$message.warning(message);
        return;
      } else {
        this.uploadDataForm.salesUploadFilePath = response.data.file_path;
        this.uploadDataForm.salesUploadFileName = response.data.file_name;
      }
    },
    onStockFileUploadSuccess: function onStockFileUploadSuccess(response, file, fileList) {
      this.stockUploadFileLoading = false;
      console.log('-----onStockFileUploadSuccess-----');
      console.log(arguments);
      if (response.code != '0') {
        var message = response.message;
        if (isNull(message)) {
          message = response;
        }
        this.$message.warning(message);
        return;
      } else {
        this.uploadDataForm.stockUploadFilePath = response.data.file_path;
        this.uploadDataForm.stockUploadFileName = response.data.file_name;
      }
    },
    onChangeFileUploadSuccess: function onChangeFileUploadSuccess(response, file, fileList) {
      this.changeUploadFileLoading = false;
      console.log('-----onChangeFileUploadSuccess-----');
      console.log(arguments);
      if (response.code != '0') {
        var message = response.message;
        if (isNull(message)) {
          message = response;
        }
        this.$message.warning(message);
        return;
      } else {
        this.uploadDataForm.changeUploadFilePath = response.data.file_path;
        this.uploadDataForm.changeUploadFileName = response.data.file_name;
      }
    },
    onPurchaseFileUploadSuccess: function onPurchaseFileUploadSuccess(response, file, fileList) {
      this.purchaseUploadFileLoading = false;
      console.log('-----onPurchaseFileUploadSuccess-----');
      console.log(arguments);
      if (response.code != '0') {
        var message = response.message;
        if (isNull(message)) {
          message = response;
        }
        this.$message.warning(message);
        return;
      } else {
        this.uploadDataForm.purchaseUploadFilePath = response.data.file_path;
        this.uploadDataForm.purchaseUploadFileName = response.data.file_name;
      }
    },
    getDistributorOverview: function getDistributorOverview() {
      var _this3 = this;
      this.listLoading = true;
      return new Promise(function (resolve, reject) {
        distributorOverview().then(function (response) {
          // console.log(response)
          if (response.data.code !== '0') {
            _this3.$notify({
              title: _this3.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          var overviewData = response.data.data;
          _this3.distributorName = overviewData.distributorName;
          _this3.uploadOpenSwitch = overviewData.uploadOpenSwitch;
          _this3.uploadScheduleId = overviewData.uploadScheduleId;
          _this3.openStartDate = overviewData.openStartDate;
          _this3.openEndDate = overviewData.openEndDate;
          _this3.billStartDate = overviewData.billStartDate;
          _this3.billEndDate = overviewData.billEndDate;
          _this3.uploadStatus = overviewData.uploadStatus;
          _this3.periodId = overviewData.periodId;
          _this3.periodUploadOpenSwitch = overviewData.periodUploadOpenSwitch;
          _this3.periodOpenStartDate = overviewData.periodOpenStartDate;
          _this3.periodOpenEndDate = overviewData.periodOpenEndDate;
          _this3.periodBillStartDate = overviewData.periodBillStartDate;
          _this3.periodBillEndDate = overviewData.periodBillEndDate;
          _this3.listLoading = false;
          resolve(response);
        }).catch(function (error) {
          _this3.listLoading = false;
          reject(error);
        });
      });
    },
    getLastUploadBatch: function getLastUploadBatch() {
      var _this4 = this;
      this.listLoading = true;
      return new Promise(function (resolve, reject) {
        _getLastUploadBatch(_this4.uploadScheduleId).then(function (response) {
          // console.log(response)
          if (response.data.code !== '0') {
            _this4.$notify({
              title: _this4.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          var lastUploadBatchData = response.data.data;
          if (lastUploadBatchData) {
            //console.log(lastUploadBatchData)
            _this4.uploadDataForm.hasPurchaseData = lastUploadBatchData.hasPurchaseData;
            _this4.uploadDataForm.purchaseUploadFilePath = lastUploadBatchData.purchaseUploadFilePath;
            _this4.uploadDataForm.hasSalesData = lastUploadBatchData.hasSalesData;
            _this4.uploadDataForm.salesUploadFilePath = lastUploadBatchData.salesUploadFilePath;
            _this4.uploadDataForm.hasStockData = lastUploadBatchData.hasStockData;
            _this4.uploadDataForm.stockUploadFilePath = lastUploadBatchData.stockUploadFilePath;
            _this4.uploadDataForm.hasChangeData = lastUploadBatchData.hasChangeData;
            _this4.uploadDataForm.changeUploadFilePath = lastUploadBatchData.changeUploadFilePath;
            Object.assign(_this4.uploadDataForm, lastUploadBatchData);
          }
          _this4.listLoading = false;
          resolve(response);
        }).catch(function (error) {
          _this4.listLoading = false;
          reject(error);
        });
      });
    },
    getBatchListData: function getBatchListData() {
      var _this5 = this;
      this.listLoading = true;
      return new Promise(function (resolve, reject) {
        batchList(_this5.listQuery).then(function (response) {
          // console.log(response)
          if (response.data.code !== '0') {
            _this5.$notify({
              title: _this5.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          _this5.listData = response.data.data;
          _this5.total = response.data.total;
          _this5.listLoading = false;
          resolve(response);
        }).catch(function (error) {
          _this5.listLoading = false;
          reject(error);
        });
      });
    },
    zeroDeclaration: function zeroDeclaration() {
      this.uploadDataForm.hasPurchaseData = 0;
      this.uploadDataForm.purchaseUploadFilePath = null;
      this.uploadDataForm.hasSalesData = 0;
      this.uploadDataForm.salesUploadFilePath = null;
      this.uploadDataForm.hasChangeData = 0;
      this.uploadDataForm.changeUploadFilePath = null;
      this.uploadDataForm.hasStockData = 0;
      this.uploadDataForm.stockUploadFilePath = null;
      this.uploadDialogVisible = true;
    },
    openUploadDialog: function openUploadDialog(type) {
      this.uploadType = type;
      if (this.uploadType == 1 && this.uploadOpenSwitch == 1 && this.uploadStatus == 1) {
        this.getLastUploadBatch();
      }
      if (this.uploadType == 2) {
        this.uploadDataForm.hasPurchaseData = 1;
        this.uploadDataForm.purchaseUploadFilePath = null;
        this.uploadDataForm.hasSalesData = 1;
        this.uploadDataForm.salesUploadFilePath = null;
        this.uploadDataForm.hasChangeData = 1;
        this.uploadDataForm.changeUploadFilePath = null;
        this.uploadDataForm.hasStockData = 0;
        this.uploadDataForm.stockUploadFilePath = null;
      }
      this.uploadDialogVisible = true;
    },
    closeUploadDialog: function closeUploadDialog() {
      this.uploadDialogVisible = false;
    },
    handleConfirmUpload: function handleConfirmUpload() {
      var _this6 = this;
      this.uploadDataForm.uploadType = this.uploadType;
      if (this.uploadDataForm.hasPurchaseData && !this.uploadDataForm.purchaseUploadFilePath) {
        this.$notify({
          title: this.$t('notification.title'),
          message: '请上传进货数据',
          type: 'warning',
          duration: 3000
        });
        return;
      }
      if (this.uploadDataForm.hasSalesData && !this.uploadDataForm.salesUploadFilePath) {
        this.$notify({
          title: this.$t('notification.title'),
          message: '请上传销售数据',
          type: 'warning',
          duration: 3000
        });
        return;
      }
      if (this.isFirstTimeUpload == 1 && this.uploadDataForm.hasStockData && !this.uploadDataForm.stockUploadFilePath) {
        this.$notify({
          title: this.$t('notification.title'),
          message: '请上传库存数据',
          type: 'warning',
          duration: 3000
        });
        return;
      }
      if (this.uploadDataForm.hasChangeData && !this.uploadDataForm.changeUploadFilePath) {
        this.$notify({
          title: this.$t('notification.title'),
          message: '请上传其他出入库数据',
          type: 'warning',
          duration: 3000
        });
        return;
      }
      this.$confirm(this.$t('messageBox.confirm.body'), this.$t('messageBox.confirm.title'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(function () {
        _this6.listLoading = true;
        return new Promise(function (resolve, reject) {
          confirmUpload(_this6.uploadType, _this6.uploadScheduleId, _this6.periodId, _this6.uploadDataForm).then(function (response) {
            //console.log(response)
            if (response.data.code === '0') {
              _this6.$notify({
                title: _this6.$t('notification.success.title'),
                message: _this6.$t('notification.success.body'),
                type: 'success',
                duration: 3000
              });
            } else {
              _this6.$notify({
                title: _this6.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 5000
              });
            }
            _this6.uploadDialogVisible = false;
            _this6.getDistributorOverview();
            _this6.getBatchListData();
            //this.listLoading = false
            resolve(response);
          }).catch(function (error) {
            _this6.listLoading = false;
            reject(error);
          });
        });
      });
    },
    getLocalTime: function getLocalTime(timestamp) {
      return getLocalTimestamp(timestamp);
    }
  }
};