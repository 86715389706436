import request from '@/utils/request';
export function pssOverview(data) {
  return request({
    url: '/dashboard/pss-overview',
    method: 'post',
    data: data
  });
}
export function stockDays(data) {
  return request({
    url: '/dashboard/stock-days',
    method: 'post',
    data: data
  });
}
export function productRank(data) {
  return request({
    url: '/dashboard/product-rank',
    method: 'post',
    data: data
  });
}
export function shopRank(data) {
  return request({
    url: '/dashboard/shop-rank',
    method: 'post',
    data: data
  });
}
export function returnGoods(data) {
  return request({
    url: '/dashboard/return-goods',
    method: 'post',
    data: data
  });
}