var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "dashboard-container" },
        [
          _c(
            "div",
            {
              staticClass: "dashboard-text",
              staticStyle: { "text-align": "left", padding: "0px 0px 0px 0px" },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("index.hello")) +
                  " , " +
                  _vm._s(_vm.distributorName) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticStyle: { color: "red" } }, [
            _vm._v("红字部分仅测试临时使用，正式功能是没有的 - Begin"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._v("\n      日常上传开关\n      "),
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.uploadOpenSwitch,
                  callback: function ($$v) {
                    _vm.uploadOpenSwitch = $$v
                  },
                  expression: "uploadOpenSwitch",
                },
              }),
              _vm._v("\n          本次日常上传是否已上传过\n      "),
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.uploadStatus,
                  callback: function ($$v) {
                    _vm.uploadStatus = $$v
                  },
                  expression: "uploadStatus",
                },
              }),
              _vm._v("\n          \n      是否首次上传\n      "),
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.isFirstTimeUpload,
                  callback: function ($$v) {
                    _vm.isFirstTimeUpload = $$v
                  },
                  expression: "isFirstTimeUpload",
                },
              }),
              _vm._v("\n          \n      月结前调整上传开关\n      "),
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.periodUploadOpenSwitch,
                  callback: function ($$v) {
                    _vm.periodUploadOpenSwitch = $$v
                  },
                  expression: "periodUploadOpenSwitch",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { color: "red" } }, [
            _vm._v("红字部分- End"),
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm.uploadOpenSwitch == 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "left",
                    padding: "0px 0px 0px 0px",
                  },
                },
                [
                  _vm._v(
                    "\n      数据上传开放中，本次开放时间：" +
                      _vm._s(_vm.openStartDate) +
                      " ～" +
                      _vm._s(_vm.openEndDate) +
                      "。\n      本次接受的单据日期范围：" +
                      _vm._s(_vm.billStartDate) +
                      " ～ " +
                      _vm._s(_vm.billEndDate) +
                      "。"
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(" "),
                  _vm.uploadStatus == 0
                    ? _c(
                        "div",
                        [
                          _vm._v("\n        您的数据上传状态是：\n        "),
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: "warning",
                                effect: "light",
                                size: "medium",
                              },
                            },
                            [_vm._v("未上传")]
                          ),
                          _vm._v("\n        请及时上传数据：\n        "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                { name: "waves", rawName: "v-waves" },
                              ],
                              staticClass: "filter-item",
                              attrs: { type: "info" },
                              on: {
                                click: function ($event) {
                                  return _vm.openUploadDialog(1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("button.openUploadWindow")) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uploadStatus == 1
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "left",
                            padding: "0px 0px 0px 0px",
                          },
                        },
                        [
                          _vm._v("\n        您的数据上传状态是：\n        "),
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: "success",
                                effect: "light",
                                size: "medium",
                              },
                            },
                            [_vm._v("已上传")]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            "\n        如果您要更正数据，请重新上传：\n        "
                          ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                { name: "waves", rawName: "v-waves" },
                              ],
                              staticClass: "filter-item",
                              attrs: { type: "info" },
                              on: {
                                click: function ($event) {
                                  return _vm.openUploadDialog(1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("button.openUploadWindow")) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n        （提醒：系统将覆盖上次上传数据，以新上传数据为准）\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "left",
                        padding: "20px 0px 0px 0px",
                      },
                    },
                    [
                      _vm._v(
                        "\n        如果该时间段内无数据，请进行零申报：\n        "
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: { type: "info" },
                          on: { click: _vm.zeroDeclaration },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.zeroDeclaration")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.uploadOpenSwitch == 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "left",
                    padding: "20px 0px 10px 0px",
                  },
                },
                [
                  !_vm.openStartDate && !_vm.openEndDate
                    ? _c("div", [
                        _vm._v("\n        尚无数据上传时间表\n      "),
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n        数据上传未开放，下次开放时间：" +
                            _vm._s(_vm.openStartDate) +
                            " ～ " +
                            _vm._s(_vm.openEndDate) +
                            "。\n      "
                        ),
                      ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm.periodUploadOpenSwitch == 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "left",
                    padding: "0px 0px 0px 0px",
                  },
                },
                [
                  _vm._v(
                    "\n      数据上传开放中，本次开放时间：" +
                      _vm._s(_vm.periodOpenStartDate) +
                      " ～" +
                      _vm._s(_vm.periodOpenEndDate) +
                      "。\n      本次接受的单据日期范围：" +
                      _vm._s(_vm.periodBillStartDate) +
                      " ～ " +
                      _vm._s(_vm.periodBillEndDate) +
                      "。"
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "left",
                        padding: "0px 0px 0px 0px",
                      },
                    },
                    [
                      _vm._v("\n        如果要补充新数据，请上传："),
                      _c("br"),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: { type: "info" },
                          on: {
                            click: function ($event) {
                              return _vm.openUploadDialog(2)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.openUploadWindow")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(
                        "\n        （提醒：如果单据已存在，系统将用新的单据覆盖原有的）\n      "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "left",
                        padding: "20px 0px 0px 0px",
                      },
                    },
                    [
                      _vm._v(
                        "\n        如果要删除原有数据，请从下方链接进入："
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "link-type",
                          attrs: {
                            to: {
                              path: "/data-view-dms/dms-rawdata/dms-rawdatapurchase-portal",
                              query: {},
                            },
                          },
                        },
                        [_c("span", [_vm._v("进货数据")])]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "link-type",
                          attrs: {
                            to: {
                              path: "/data-view-dms/dms-rawdata/dms-rawdatasales-portal",
                              query: {},
                            },
                          },
                        },
                        [_c("span", [_vm._v("销售数据")])]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "link-type",
                          attrs: {
                            to: {
                              path: "/data-view-dms/dms-rawdata/dms-rawdatachange-portal",
                              query: {},
                            },
                          },
                        },
                        [_c("span", [_vm._v("其他出入库数据")])]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _c("br"),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.periodUploadOpenSwitch == 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "left",
                    padding: "20px 0px 10px 0px",
                  },
                },
                [
                  !_vm.periodOpenStartDate && !_vm.periodOpenEndDate
                    ? _c("div", [
                        _vm._v("\n        尚无数据上传时间表\n      "),
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n        数据上传未开放，下次开放时间：" +
                            _vm._s(_vm.periodOpenStartDate) +
                            " ～ " +
                            _vm._s(_vm.periodOpenStartDate) +
                            "。\n      "
                        ),
                      ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "60%",
                visible: _vm.uploadDialogVisible,
                title: "上传进销存数据",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.uploadDialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "0px", align: "center" },
                  attrs: { width: "100%" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "filter-container" },
                    [
                      _c(
                        "el-form",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          ref: "uploadDataForm",
                          attrs: {
                            model: _vm.uploadDataForm,
                            "label-width": "150px",
                          },
                        },
                        [
                          _vm.uploadType == 1
                            ? _c(
                                "el-row",
                                { staticStyle: { "margin-bottom": "20px" } },
                                [
                                  _vm._v(
                                    "\n              本次接受的单据日期范围：" +
                                      _vm._s(_vm.billStartDate) +
                                      " ～ " +
                                      _vm._s(_vm.billEndDate) +
                                      "。\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.uploadType == 2
                            ? _c(
                                "el-row",
                                { staticStyle: { "margin-bottom": "20px" } },
                                [
                                  _vm._v(
                                    "\n              本次接受的单据日期范围：" +
                                      _vm._s(_vm.periodBillStartDate) +
                                      " ～ " +
                                      _vm._s(_vm.periodBillEndDate) +
                                      "。\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { staticStyle: { "margin-bottom": "20px" } },
                            [
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "18px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-upload" }),
                                    _vm._v("  上传进货数据：\n              "),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-link",
                                  {
                                    directives: [
                                      { name: "waves", rawName: "v-waves" },
                                    ],
                                    attrs: {
                                      target: "_blank",
                                      type: "primary",
                                      icon: "el-icon-download",
                                      href: "/static/portal-data-template/portal-purchase.xlsx",
                                      download: "进货数据模板.xlsx",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("importFile.downloadTemplate")
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      padding: "0px 0px 0px 20px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 1 },
                                        model: {
                                          value:
                                            _vm.uploadDataForm.hasPurchaseData,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.uploadDataForm,
                                              "hasPurchaseData",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "uploadDataForm.hasPurchaseData",
                                        },
                                      },
                                      [
                                        _vm.uploadType == 1
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    该日期范围有数据\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.uploadType == 2
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    新增/更新数据\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 0 },
                                        model: {
                                          value:
                                            _vm.uploadDataForm.hasPurchaseData,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.uploadDataForm,
                                              "hasPurchaseData",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "uploadDataForm.hasPurchaseData",
                                        },
                                      },
                                      [
                                        _vm.uploadType == 1
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    该日期范围无数据\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.uploadType == 2
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    数据不变\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm.uploadDataForm.hasPurchaseData == 1
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "left",
                                        padding: "0px 0px 0px 20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          attrs: {
                                            action: _vm.action,
                                            headers: _vm.myHeaders,
                                            data: _vm.purchaseRequest,
                                            "on-preview":
                                              _vm.handlePurchaseFileUploadPreview,
                                            "on-remove":
                                              _vm.handlePurchaseFileRemove,
                                            "before-upload":
                                              _vm.beforePurchaseFileUpload,
                                            "on-success":
                                              _vm.onPurchaseFileUploadSuccess,
                                            "on-error":
                                              _vm.onPurchaseFileUploadError,
                                            "auto-upload": true,
                                            limit: 100,
                                            "file-list": _vm.purchaseFileList,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "trigger",
                                                size: "small",
                                                loading:
                                                  _vm.purchaseUploadFileLoading,
                                                type: "primary",
                                              },
                                              slot: "trigger",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("button.uploadData")
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "el-upload__tip",
                                              staticStyle: { color: "red" },
                                              attrs: { slot: "tip" },
                                              slot: "tip",
                                            },
                                            [
                                              _vm._v(
                                                "只能上传.xls格式文件，且不超过500M"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { staticStyle: { "margin-bottom": "20px" } },
                            [
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "18px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-upload" }),
                                    _vm._v("  上传销售数据：\n              "),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-link",
                                  {
                                    directives: [
                                      { name: "waves", rawName: "v-waves" },
                                    ],
                                    attrs: {
                                      target: "_blank",
                                      type: "primary",
                                      icon: "el-icon-download",
                                      href: "/static/portal-data-template/portal-sales.xlsx",
                                      download: "销售数据模板.xlsx",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("importFile.downloadTemplate")
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      padding: "0px 0px 0px 20px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 1 },
                                        model: {
                                          value:
                                            _vm.uploadDataForm.hasSalesData,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.uploadDataForm,
                                              "hasSalesData",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "uploadDataForm.hasSalesData",
                                        },
                                      },
                                      [
                                        _vm.uploadType == 1
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    该日期范围有数据\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.uploadType == 2
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    新增/更新数据\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 0 },
                                        model: {
                                          value:
                                            _vm.uploadDataForm.hasSalesData,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.uploadDataForm,
                                              "hasSalesData",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "uploadDataForm.hasSalesData",
                                        },
                                      },
                                      [
                                        _vm.uploadType == 1
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    该日期范围无数据\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.uploadType == 2
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    数据不变\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm.uploadDataForm.hasSalesData == 1
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "left",
                                        padding: "0px 0px 0px 20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          attrs: {
                                            action: _vm.action,
                                            headers: _vm.myHeaders,
                                            data: _vm.salesRequest,
                                            "on-preview":
                                              _vm.handleSalesFileUploadPreview,
                                            "on-remove":
                                              _vm.handleSalesFileRemove,
                                            "before-upload":
                                              _vm.beforeSalesFileUpload,
                                            "on-success":
                                              _vm.onSalesFileUploadSuccess,
                                            "on-error":
                                              _vm.onSalesFileUploadError,
                                            "auto-upload": true,
                                            limit: 100,
                                            "file-list": _vm.salesFileList,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "trigger",
                                                size: "small",
                                                loading:
                                                  _vm.salesUploadFileLoading,
                                                type: "primary",
                                              },
                                              slot: "trigger",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("button.uploadData")
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "el-upload__tip",
                                              staticStyle: { color: "red" },
                                              attrs: { slot: "tip" },
                                              slot: "tip",
                                            },
                                            [
                                              _vm._v(
                                                "只能上传.xls格式文件，且不超过500M"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _vm.isFirstTimeUpload == 1
                            ? _c(
                                "el-row",
                                { staticStyle: { "margin-bottom": "20px" } },
                                [
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "18px",
                                          "font-weight": "bold",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-upload",
                                        }),
                                        _vm._v(
                                          "  上传库存数据：\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-link",
                                      {
                                        directives: [
                                          { name: "waves", rawName: "v-waves" },
                                        ],
                                        attrs: {
                                          target: "_blank",
                                          type: "primary",
                                          icon: "el-icon-download",
                                          href: "/static/portal-data-template/portal-stock.xlsx",
                                          download: "库存数据模板.xlsx",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "importFile.downloadTemplate"
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          padding: "0px 0px 0px 20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: 1 },
                                            model: {
                                              value:
                                                _vm.uploadDataForm.hasStockData,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.uploadDataForm,
                                                  "hasStockData",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "uploadDataForm.hasStockData",
                                            },
                                          },
                                          [
                                            _vm.uploadType == 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                    该日期范围有数据\n                  "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.uploadType == 2
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                    新增/更新数据\n                  "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: 0 },
                                            model: {
                                              value:
                                                _vm.uploadDataForm.hasStockData,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.uploadDataForm,
                                                  "hasStockData",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "uploadDataForm.hasStockData",
                                            },
                                          },
                                          [
                                            _vm.uploadType == 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                    该日期范围无数据\n                  "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.uploadType == 2
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                    数据不变\n                  "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isFirstTimeUpload == 1
                            ? _c(
                                "el-row",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm.uploadDataForm.hasStockData == 1
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            padding: "0px 0px 0px 20px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              attrs: {
                                                action: _vm.action,
                                                headers: _vm.myHeaders,
                                                data: _vm.stockRequest,
                                                "on-preview":
                                                  _vm.handleStockFileUploadPreview,
                                                "on-remove":
                                                  _vm.handleStockFileRemove,
                                                "before-upload":
                                                  _vm.beforeStockFileUpload,
                                                "on-success":
                                                  _vm.onStockFileUploadSuccess,
                                                "on-error":
                                                  _vm.onStockFileUploadError,
                                                "auto-upload": true,
                                                limit: 100,
                                                "file-list": _vm.stockFileList,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    slot: "trigger",
                                                    size: "small",
                                                    loading:
                                                      _vm.stockUploadFileLoading,
                                                    type: "primary",
                                                  },
                                                  slot: "trigger",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "button.uploadData"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "el-upload__tip",
                                                  staticStyle: { color: "red" },
                                                  attrs: { slot: "tip" },
                                                  slot: "tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "只能上传.xls格式文件，且不超过500M"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isFirstTimeUpload == 1
                            ? _c("el-divider")
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { staticStyle: { "margin-bottom": "20px" } },
                            [
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "18px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-upload" }),
                                    _vm._v(
                                      "  上传其他出入库数据：\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-link",
                                  {
                                    directives: [
                                      { name: "waves", rawName: "v-waves" },
                                    ],
                                    attrs: {
                                      target: "_blank",
                                      type: "primary",
                                      icon: "el-icon-download",
                                      href: "/static/portal-data-template/portal-other.xlsx",
                                      download: "其他出入库数据模板.xlsx",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("importFile.downloadTemplate")
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      padding: "0px 0px 0px 20px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 1 },
                                        model: {
                                          value:
                                            _vm.uploadDataForm.hasChangeData,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.uploadDataForm,
                                              "hasChangeData",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "uploadDataForm.hasChangeData",
                                        },
                                      },
                                      [
                                        _vm.uploadType == 1
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    该日期范围有数据\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.uploadType == 2
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    新增/更新数据\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: 0 },
                                        model: {
                                          value:
                                            _vm.uploadDataForm.hasChangeData,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.uploadDataForm,
                                              "hasChangeData",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "uploadDataForm.hasChangeData",
                                        },
                                      },
                                      [
                                        _vm.uploadType == 1
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    该日期范围无数据\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.uploadType == 2
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    数据不变\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm.uploadDataForm.hasChangeData == 1
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "left",
                                        padding: "0px 0px 0px 20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          attrs: {
                                            action: _vm.action,
                                            headers: _vm.myHeaders,
                                            data: _vm.changeRequest,
                                            "on-preview":
                                              _vm.handleChangeFileUploadPreview,
                                            "on-remove":
                                              _vm.handleChangeFileRemove,
                                            "before-upload":
                                              _vm.beforeChangeFileUpload,
                                            "on-success":
                                              _vm.onChangeFileUploadSuccess,
                                            "on-error":
                                              _vm.onChangeFileUploadError,
                                            "auto-upload": true,
                                            limit: 100,
                                            "file-list": _vm.changeFileList,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "trigger",
                                                size: "small",
                                                loading:
                                                  _vm.changeUploadFileLoading,
                                                type: "primary",
                                              },
                                              slot: "trigger",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("button.uploadData")
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "el-upload__tip",
                                              staticStyle: { color: "red" },
                                              attrs: { slot: "tip" },
                                              slot: "tip",
                                            },
                                            [
                                              _vm._v(
                                                "只能上传.xls格式文件，且不超过500M"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "margin-top": "20px",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    { name: "waves", rawName: "v-waves" },
                                  ],
                                  staticClass: "filter-item",
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleConfirmUpload },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("button.confirmUpload")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    { name: "waves", rawName: "v-waves" },
                                  ],
                                  staticClass: "filter-item",
                                  on: { click: _vm.closeUploadDialog },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("button.close")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-row"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          ref: "uploadTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.listData,
            "row-key": _vm.rowKey,
            "min-height": "510",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.tableColumns.filter(function (item) {
                return item.code == "b_upload_time"
              })[0].label,
              prop: _vm.tableColumns.filter(function (item) {
                return item.code == "b_upload_time"
              })[0].code,
              "min-width": _vm.tableColumns.filter(function (item) {
                return item.code == "b_upload_time"
              })[0].width,
              "show-overflow-tooltip": "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row["b_upload_time"]
                      ? _c("span", [_c("i", { staticClass: "el-icon-time" })])
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getLocalTime(scope.row["b_upload_time"])) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.tableColumns.filter(function (item) {
                return item.code == "b_upload_type"
              })[0].label,
              prop: _vm.tableColumns.filter(function (item) {
                return item.code == "b_upload_type"
              })[0].code,
              "min-width": _vm.tableColumns.filter(function (item) {
                return item.code == "b_upload_type"
              })[0].width,
              "show-overflow-tooltip": "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row["b_upload_type"] == 1
                            ? "日常上传"
                            : "月结前调整"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.tableColumns.filter(function (item) {
                return item.code == "b_is_valid"
              })[0].label,
              prop: _vm.tableColumns.filter(function (item) {
                return item.code == "b_is_valid"
              })[0].code,
              "min-width": _vm.tableColumns.filter(function (item) {
                return item.code == "b_is_valid"
              })[0].width,
              "show-overflow-tooltip": "",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.tableColumns.filter(function (item) {
                return item.code == "b_has_purchase_data"
              })[0].label,
              prop: _vm.tableColumns.filter(function (item) {
                return item.code == "b_has_purchase_data"
              })[0].code,
              "min-width": _vm.tableColumns.filter(function (item) {
                return item.code == "b_has_purchase_data"
              })[0].width,
              "show-overflow-tooltip": "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row["b_has_purchase_data"] == 1
                      ? _c(
                          "el-tag",
                          {
                            key: 1,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "success", effect: "light" },
                          },
                          [_vm._v("\n          已上传\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_has_purchase_data"] == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading: scope.row.__downloadPurchaseFileLoading,
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadPurchaseFile(
                                  $event,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("\n          下载\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_has_purchase_data"] == 0
                      ? _c("span", [_vm._v("\n          -\n        ")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.__downloadPurchaseFileLoading
                      ? _c("el-progress", {
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            "text-inside": true,
                            "stroke-width": 20,
                            percentage:
                              scope.row.__downloadPurchaseFileProgress,
                            status: "success",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.tableColumns.filter(function (item) {
                return item.code == "b_purchase_data_check_status"
              })[0].label,
              prop: _vm.tableColumns.filter(function (item) {
                return item.code == "b_purchase_data_check_status"
              })[0].code,
              "min-width": _vm.tableColumns.filter(function (item) {
                return item.code == "b_purchase_data_check_status"
              })[0].width,
              "show-overflow-tooltip": "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row["b_has_purchase_data"] == 1 &&
                    scope.row["b_purchase_data_check_status"] == null
                      ? _c(
                          "el-tag",
                          {
                            key: 2,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "info", effect: "light" },
                          },
                          [_vm._v("\n          检查中\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_purchase_data_check_status"] == 1
                      ? _c(
                          "el-tag",
                          {
                            key: 1,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "success", effect: "light" },
                          },
                          [_vm._v("\n          已通过\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_purchase_data_check_status"] == 0
                      ? _c(
                          "el-tag",
                          {
                            key: 0,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "danger", effect: "light" },
                          },
                          [_vm._v("\n          有错误\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_purchase_data_check_status"] == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading:
                                scope.row.__downloadPurchaseErrorFileLoading,
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadPurchaseErrorFile(
                                  $event,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("\n          下载\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_has_purchase_data"] == 0
                      ? _c("span", [_vm._v("\n          -\n        ")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.__downloadPurchaseErrorFileLoading
                      ? _c("el-progress", {
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            "text-inside": true,
                            "stroke-width": 20,
                            percentage:
                              scope.row.__downloadPurchaseErrorFileProgress,
                            status: "success",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.tableColumns.filter(function (item) {
                return item.code == "b_has_sales_data"
              })[0].label,
              prop: _vm.tableColumns.filter(function (item) {
                return item.code == "b_has_sales_data"
              })[0].code,
              "min-width": _vm.tableColumns.filter(function (item) {
                return item.code == "b_has_sales_data"
              })[0].width,
              "show-overflow-tooltip": "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row["b_has_sales_data"] == 1
                      ? _c(
                          "el-tag",
                          {
                            key: 1,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "success", effect: "light" },
                          },
                          [_vm._v("\n          已上传\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_has_sales_data"] == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading: scope.row.__downloadSalesFileLoading,
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadSalesFile($event, scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          下载\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_has_sales_data"] == 0
                      ? _c("span", [_vm._v("\n          -\n        ")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.__downloadSalesFileLoading
                      ? _c("el-progress", {
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            "text-inside": true,
                            "stroke-width": 20,
                            percentage: scope.row.__downloadSalesFileProgress,
                            status: "success",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.tableColumns.filter(function (item) {
                return item.code == "b_sales_data_check_status"
              })[0].label,
              prop: _vm.tableColumns.filter(function (item) {
                return item.code == "b_sales_data_check_status"
              })[0].code,
              "min-width": _vm.tableColumns.filter(function (item) {
                return item.code == "b_sales_data_check_status"
              })[0].width,
              "show-overflow-tooltip": "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row["b_has_sales_data"] == 1 &&
                    scope.row["b_sales_data_check_status"] == null
                      ? _c(
                          "el-tag",
                          {
                            key: 2,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "info", effect: "light" },
                          },
                          [_vm._v("\n          检查中\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_sales_data_check_status"] == 1
                      ? _c(
                          "el-tag",
                          {
                            key: 1,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "success", effect: "light" },
                          },
                          [_vm._v("\n          已通过\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_sales_data_check_status"] == 0
                      ? _c(
                          "el-tag",
                          {
                            key: 0,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "danger", effect: "light" },
                          },
                          [_vm._v("\n          有错误\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_sales_data_check_status"] == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading:
                                scope.row.__downloadSalesErrorFileLoading,
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadSalesErrorFile(
                                  $event,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("\n          下载\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_has_sales_data"] == 0
                      ? _c("span", [_vm._v("\n          -\n        ")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.__downloadSalesErrorFileLoading
                      ? _c("el-progress", {
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            "text-inside": true,
                            "stroke-width": 20,
                            percentage:
                              scope.row.__downloadSalesErrorFileProgress,
                            status: "success",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.tableColumns.filter(function (item) {
                return item.code == "b_has_change_data"
              })[0].label,
              prop: _vm.tableColumns.filter(function (item) {
                return item.code == "b_has_change_data"
              })[0].code,
              "min-width": _vm.tableColumns.filter(function (item) {
                return item.code == "b_has_change_data"
              })[0].width,
              "show-overflow-tooltip": "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row["b_has_change_data"] == 1
                      ? _c(
                          "el-tag",
                          {
                            key: 1,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "success", effect: "light" },
                          },
                          [_vm._v("\n          已上传\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_has_change_data"] == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading: scope.row.__downloadChangeFileLoading,
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadChangeFile($event, scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          下载\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_has_change_data"] == 0
                      ? _c("span", [_vm._v("\n          -\n        ")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.__downloadChangeFileLoading
                      ? _c("el-progress", {
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            "text-inside": true,
                            "stroke-width": 20,
                            percentage: scope.row.__downloadChangeFileProgress,
                            status: "success",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.tableColumns.filter(function (item) {
                return item.code == "b_change_data_check_status"
              })[0].label,
              prop: _vm.tableColumns.filter(function (item) {
                return item.code == "b_change_data_check_status"
              })[0].code,
              "min-width": _vm.tableColumns.filter(function (item) {
                return item.code == "b_change_data_check_status"
              })[0].width,
              "show-overflow-tooltip": "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row["b_has_change_data"] == 1 &&
                    scope.row["b_change_data_check_status"] == null
                      ? _c(
                          "el-tag",
                          {
                            key: 2,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "info", effect: "light" },
                          },
                          [_vm._v("\n          检查中\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_change_data_check_status"] == 1
                      ? _c(
                          "el-tag",
                          {
                            key: 1,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "success", effect: "light" },
                          },
                          [_vm._v("\n          已通过\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_change_data_check_status"] == 0
                      ? _c(
                          "el-tag",
                          {
                            key: 0,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "danger", effect: "light" },
                          },
                          [_vm._v("\n          有错误\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_change_data_check_status"] == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading:
                                scope.row.__downloadChangeErrorFileLoading,
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadChangeErrorFile(
                                  $event,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("\n          下载\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_has_change_data"] == 0
                      ? _c("span", [_vm._v("\n          -\n        ")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.__downloadChangeErrorFileLoading
                      ? _c("el-progress", {
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            "text-inside": true,
                            "stroke-width": 20,
                            percentage:
                              scope.row.__downloadChangeErrorFileProgress,
                            status: "success",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.tableColumns.filter(function (item) {
                return item.code == "b_has_stock_data"
              })[0].label,
              prop: _vm.tableColumns.filter(function (item) {
                return item.code == "b_has_stock_data"
              })[0].code,
              "min-width": _vm.tableColumns.filter(function (item) {
                return item.code == "b_has_stock_data"
              })[0].width,
              "show-overflow-tooltip": "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row["b_has_stock_data"] == 1
                      ? _c(
                          "el-tag",
                          {
                            key: 1,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "success", effect: "light" },
                          },
                          [_vm._v("\n          已上传\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_has_stock_data"] == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading: scope.row.__downloadStockFileLoading,
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadStockFile($event, scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          下载\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_has_stock_data"] == 0
                      ? _c("span", [_vm._v("\n          -\n        ")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.__downloadStockFileLoading
                      ? _c("el-progress", {
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            "text-inside": true,
                            "stroke-width": 20,
                            percentage: scope.row.__downloadStockFileProgress,
                            status: "success",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.tableColumns.filter(function (item) {
                return item.code == "b_stock_data_check_status"
              })[0].label,
              prop: _vm.tableColumns.filter(function (item) {
                return item.code == "b_stock_data_check_status"
              })[0].code,
              "min-width": _vm.tableColumns.filter(function (item) {
                return item.code == "b_stock_data_check_status"
              })[0].width,
              "show-overflow-tooltip": "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row["b_has_stock_data"] == 1 &&
                    scope.row["b_stock_data_check_status"] == null
                      ? _c(
                          "el-tag",
                          {
                            key: 2,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "info", effect: "light" },
                          },
                          [_vm._v("\n          检查中\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_stock_data_check_status"] == 1
                      ? _c(
                          "el-tag",
                          {
                            key: 1,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "success", effect: "light" },
                          },
                          [_vm._v("\n          已通过\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_stock_data_check_status"] == 0
                      ? _c(
                          "el-tag",
                          {
                            key: 0,
                            staticStyle: { "text-align": "center" },
                            attrs: { type: "danger", effect: "light" },
                          },
                          [_vm._v("\n          有错误\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_stock_data_check_status"] == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading:
                                scope.row.__downloadStockErrorFileLoading,
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadStockErrorFile(
                                  $event,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("\n          下载\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row["b_has_stock_data"] == 0
                      ? _c("span", [_vm._v("\n          -\n        ")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.__downloadStockErrorFileLoading
                      ? _c("el-progress", {
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            "text-inside": true,
                            "stroke-width": 20,
                            percentage:
                              scope.row.__downloadStockErrorFileProgress,
                            status: "success",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getBatchListData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h3", [_vm._v("日常上传")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h3", [_vm._v("月结前调整上传")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "text-align": "left", padding: "0px 0px 10px 0px" } },
      [_c("h3", [_vm._v("已上传数据")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }