import _createForOfIteratorHelper from "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
//
//
//
//

import echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from "./mixins/resize";
import { stockDays } from '@/api/dashboard';
var animationDuration = 6000;
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data: function data() {
    return {
      chart: null,
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'cross'
          }
        },
        grid: {
          top: 40,
          left: '3%',
          right: '5%',
          bottom: '3%',
          containLabel: true
        },
        legend: {
          data: [this.$t('dashboard.stock_days_bar_chart.stock_standard_amount'), this.$t('dashboard.stock_days_bar_chart.stock_turn_over_days')]
        },
        xAxis: [{
          type: 'category',
          data: ['Brand'],
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: 'value',
          name: this.$t('dashboard.stock_days_bar_chart.stock_standard_amount'),
          position: 'left',
          axisLabel: {
            formatter: '{value}'
          }
        }, {
          type: 'value',
          name: this.$t('dashboard.stock_days_bar_chart.stock_turn_over_days'),
          min: 0,
          //max:180,
          position: 'right',
          offset: 0,
          axisLabel: {
            formatter: '{value}'
          }
        }],
        series: [{
          name: this.$t('dashboard.stock_days_bar_chart.stock_standard_amount'),
          type: 'bar',
          data: [0],
          animationDuration: animationDuration
        }, {
          name: this.$t('dashboard.stock_days_bar_chart.stock_turn_over_days'),
          type: 'line',
          yAxisIndex: 1,
          data: [0],
          animationDuration: animationDuration
        }]
      }
    };
  },
  computed: {
    language: function language() {
      return this.$store.getters.language;
    }
  },
  watch: {
    language: function language(value) {
      //console.log(value)
      this.option.legend.data[0] = this.$t('dashboard.stock_days_bar_chart.stock_standard_amount');
      this.option.legend.data[1] = this.$t('dashboard.stock_days_bar_chart.stock_turn_over_days');
      this.option.yAxis[0].name = this.$t('dashboard.stock_days_bar_chart.stock_standard_amount');
      this.option.yAxis[1].name = this.$t('dashboard.stock_days_bar_chart.stock_turn_over_days');
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.chart.setOption(this.option);
    },
    refreshChart: function refreshChart(reportRequest) {
      this.reportRequest = reportRequest;
      this.getStockDays();
      //this.chart.setOption(option)
    },
    getStockDays: function getStockDays() {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        stockDays(_this2.reportRequest).then(function (response) {
          //console.log(response.data)
          if (response.data.code !== '0') {
            _this2.$notify({
              title: _this2.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          var chartData = response.data.data;
          if (chartData && chartData.length > 0) {
            var xAxisData = [];
            var series = [];
            var seriesItem1 = {
              name: _this2.$t('dashboard.stock_days_bar_chart.stock_standard_amount'),
              type: 'bar',
              data: [],
              animationDuration: animationDuration
            };
            var seriesItem2 = {
              name: _this2.$t('dashboard.stock_days_bar_chart.stock_turn_over_days'),
              type: 'line',
              data: [],
              animationDuration: animationDuration,
              yAxisIndex: 1
            };
            var _iterator = _createForOfIteratorHelper(chartData),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var item = _step.value;
                xAxisData.push(item.brand);
                seriesItem1.data.push(item.product_stock_standard_amount);
                seriesItem2.data.push(item.product_stock_days);
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            series.push(seriesItem1);
            series.push(seriesItem2);
            _this2.option.xAxis[0].data = xAxisData;
            _this2.option.series = series;
          } else {
            _this2.option.legend.data = [];
            _this2.option.xAxis[0].data = [];
            _this2.option.series = [];
          }
          // console.log(this.option)
          if (_this2.chart) {
            _this2.chart.clear();
            _this2.chart.setOption(_this2.option);
          }
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  }
};