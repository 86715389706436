var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("panel-group", { ref: "panelGroup" }),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "16px 16px 0",
            "margin-bottom": "32px",
          },
        },
        [_c("stock-days-bar-chart", { ref: "stockDaysBarChart" })],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "16px 16px 0",
            "margin-bottom": "32px",
          },
        },
        [
          _c("product-rank-table", { ref: "productRankTable" }),
          _vm._v(" "),
          _c("shop-rank-table", { ref: "shopRankTable" }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "16px 16px 0",
            "margin-bottom": "32px",
          },
        },
        [_c("return-goods-line-chart", { ref: "returnGoodsLineChart" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }