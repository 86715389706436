import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
import _objectSpread from "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { getLocalTimestamp, deepClone } from '@/utils';
import { generalQuery } from '@/api/general';
import checkPermission from '@/utils/permission'; // 权限判断函数

export default {
  name: 'UserDashboard',
  data: function data() {
    return {
      activeTabName: 'tab1',
      tableName: "v_client_audit_process_instance",
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      tableColumns3: [],
      tableColumns4: [],
      listData1: [],
      total1: 0,
      listData2: [],
      total2: 0,
      listData3: [],
      total3: 0,
      listData4: [],
      total4: 0,
      loading: false,
      hasShopAuthorizationPrivilege: checkPermission('master.shop.authorization.audit'),
      hasPrintAuthorizationPrivilege: checkPermission('master.shop.authorization.print'),
      canAdd: checkPermission('master.shop.add'),
      //true,
      canEdit: checkPermission('master.shop.edit') //true,
    };
  },
  computed: _objectSpread({}, mapGetters(['name', 'avatar', 'roles'])),
  created: function created() {
    // this.hasShopAuditPrivilege = this.$store.getters.hasShopAuditPrivilege
    var v = 0;
    if (this.$route.query.viewType != null) {
      v = this.$route.query.viewType;
    } else if (this.hasShopAuthorizationPrivilege) {
      v = 1;
    } else if (this.hasPrintAuthorizationPrivilege) {
      v = 2;
    }
    this.activeTabName = 'tab' + v;
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      //console.log('loadData....')
      this.setTableColumns();
      this.getListData(1);
      this.getListData(2);
    },
    handleClick: function handleClick(tab, event) {
      // console.log(tab, event);
      // if(tab.name=='tab1'){
      //   this.getListData(1)
      // } else if(tab.name=='tab2'){
      //   this.getListData(2)
      // } else if(tab.name=='tab3'){
      //   this.getListData(3)
      // }      
    },
    setTableColumns: function setTableColumns() {
      var _this = this;
      var tdds = this.$store.getters.tableDataDictionary.tables;
      if (!tdds) {
        return;
      }
      this.tableColumns = tdds.find(function (value) {
        return value.name === _this.tableName;
      }).tableColumns;
      //this.tableColumns2 = deepClone(this.tableColumns)
      var columns1 = ['b_apply_time', 'b_apply_user_name', 'b_distributor_id', 'b_city_id', 'b_shop_name', 'b_shop_address', 'b_shop_code', 'b_audit_step_id', 'b_audit_status', 'c_rec_create_time'];
      this.tableColumns1 = this.tableColumns.filter(function (column) {
        return columns1.includes(column.code);
      });
      var columns2 = ['b_apply_time', 'b_apply_user_name', 'b_distributor_id', 'b_city_id', 'b_shop_name', 'b_shop_address', 'b_shop_code', 'b_has_print', 'c_rec_create_time'];
      this.tableColumns2 = this.tableColumns.filter(function (column) {
        return columns2.includes(column.code);
      });
    },
    getListData: function getListData(v) {
      var _this2 = this;
      // console.log('getListData')
      this.loading = true;
      var listQuery = {
        page: 1,
        size: 5,
        language: this.$store.getters.language,
        param: {
          viewType: v
        },
        extra: {}
      };
      if (v == 1) {
        listQuery.param['b_audit_status'] = [1];
        this.listData1 = [];
      } else if (v == 2) {
        this.listData2 = [];
      }
      return new Promise(function (resolve, reject) {
        generalQuery("/shop-audit/audit", listQuery).then(function (response) {
          // console.log(response)
          if (response.data.code !== '0') {
            _this2.$notify({
              title: _this2.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          if (v == 1) {
            _this2.listData1 = response.data.data;
            _this2.total1 = response.data.total;
            if (_this2.total1 == null) _this2.total1 = 0;
          } else if (v == 2) {
            _this2.listData2 = response.data.data;
            _this2.total2 = response.data.total;
            if (_this2.total2 == null) _this2.total2 = 0;
          } else if (v == 3) {
            _this2.listData3 = response.data.data;
            _this2.total3 = response.data.total;
            if (_this2.total3 == null) _this2.total3 = 0;
          }
          _this2.loading = false;
          resolve(response);
        }).catch(function (error) {
          _this2.loading = false;
          reject(error);
        });
      });
    },
    getLocalDate: function getLocalDate(timestamp) {
      var timeString = getLocalTimestamp(timestamp);
      return timeString; //.substring(0, 10)
    },
    viewMore: function viewMore(vType) {
      this.$router.push('/master/shop/audit/list?viewType=' + vType);
    },
    addShop: function addShop() {
      this.$router.push('/master/shop/add');
    },
    updateShop: function updateShop() {
      this.$router.push('/master/shop/list');
    }
  }
};