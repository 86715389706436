import request from '@/utils/request';
export function generalQuery(url, data) {
  if (url.indexOf('?') == -1) {
    url = url + '/query';
  }
  return request({
    url: url,
    method: 'post',
    data: data
  });
}
export function generalDelete(url, id) {
  return request({
    url: url + '/delete',
    method: 'delete',
    params: {
      id: id
    }
  });
}
export function generalDetail(url, id) {
  return request({
    url: url + '/detail',
    method: 'get',
    params: {
      id: id
    }
  });
}
export function generalDetailNewDefault(url) {
  return request({
    url: url + '/detail-new-default',
    method: 'get'
  });
}
export function generalDetailCopyFromAudit(url, auditProcessInstanceId) {
  return request({
    url: url + '/detail-copy-from-audit',
    method: 'get',
    params: {
      auditProcessInstanceId: auditProcessInstanceId
    }
  });
}
export function generalSave(url, id, data) {
  if (id && id !== 0) {
    return request({
      url: url + '/update',
      method: 'put',
      params: {
        id: id
      },
      data: data
    });
  } else {
    return request({
      url: url + '/add',
      method: 'post',
      data: data
    });
  }
}
export function generalBatchUpdate(url, ids, data) {
  return request({
    url: url + '/batch-update',
    method: 'put',
    params: {
      ids: ids
    },
    data: data
  });
}
export function generalTree(url) {
  return request({
    url: url,
    method: 'post'
  });
}
export function generalValueLabelList(url) {
  return request({
    url: url,
    method: 'post'
  });
}
export function generalDownload(url, data) {
  return request({
    url: url + '/download-excel',
    method: 'post',
    data: data,
    responseType: 'blob'
  });
}
export function generalChangeLog(url, id) {
  return request({
    url: url + '/change-log',
    method: 'post',
    params: {
      id: id
    }
  });
}