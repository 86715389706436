//
//
//
//

import echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from "./mixins/resize";
var animationDuration = 6000;
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '250px'
    },
    height: {
      type: String,
      default: '250px'
    }
  },
  data: function data() {
    return {
      chart: null,
      option: {
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%'
        },
        grid: {
          top: 0,
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true
        },
        series: [{
          name: '业务指标',
          type: 'gauge',
          radius: '100%',
          detail: {
            formatter: '{value}%'
          },
          data: [{
            value: 0,
            name: ''
          }]
        }]
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    refreshChart: function refreshChart(data) {
      if (this.chart) {
        this.chart.clear();
        if (data[0].value == null) data[0].value = 0;
        this.option.series[0].data = data;
        // console.log(this.option)
        this.chart.setOption(this.option);
      }
    },
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.chart.setOption(this.option);
    }
  }
};