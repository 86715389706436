var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "48%",
        padding: "0px",
        "text-align": "center",
        float: "left",
        "margin-left": "10px",
      },
    },
    [
      _c("div", [
        _c("h4", [
          _vm._v(
            "Top 10 " +
              _vm._s(_vm.$t("dashboard.shop_rank_table.terminal_sales"))
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.header.length > 0
        ? _c(
            "el-table",
            {
              attrs: {
                data: _vm.listData,
                stripe: "",
                border: true,
                size: "mini",
                "row-style": _vm.iRowStyle,
                "cell-style": _vm.iCellStyle,
                "header-row-style": _vm.iHeaderRowStyle,
                "header-cell-style": _vm.iHeaderCellStyle,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.header.filter(function (n) {
                    return n.code == "rank"
                  })[0].label,
                  width: "50",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n        " + _vm._s(scope.row.rank) + "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1432988971
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.header.filter(function (n) {
                    return n.code == "shop2"
                  })[0].label,
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n        " + _vm._s(scope.row.shop2) + "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  56443819
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.header.filter(function (n) {
                    return n.code == "rank_diff2"
                  })[0].label,
                  width: "50",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.rank_diff2 != null &&
                          scope.row.rank_diff2.indexOf("+") == 0
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(scope.row.rank_diff2.substr(1)) +
                                      "\n          "
                                  ),
                                  _c("svg-icon", {
                                    attrs: {
                                      "class-name": "arrow-down-icon",
                                      "icon-class": "arrow-up",
                                    },
                                  }),
                                ],
                                1
                              )
                            : scope.row.rank_diff2 != null &&
                              scope.row.rank_diff2.indexOf("-") == 0 &&
                              scope.row.rank_diff2.indexOf("--") != 0
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(scope.row.rank_diff2.substr(1)) +
                                      "\n          "
                                  ),
                                  _c("svg-icon", {
                                    attrs: {
                                      "class-name": "arrow-down-icon",
                                      "icon-class": "arrow-down",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(scope.row.rank_diff2) +
                                    "\n        "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1584099683
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.header.filter(function (n) {
                    return n.code == "shop1"
                  })[0].label,
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n        " + _vm._s(scope.row.shop1) + "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1410156104
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.header.filter(function (n) {
                    return n.code == "rank_diff1"
                  })[0].label,
                  width: "50",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.rank_diff1 != null &&
                          scope.row.rank_diff1.indexOf("+") == 0
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(scope.row.rank_diff1.substr(1)) +
                                      "\n          "
                                  ),
                                  _c("svg-icon", {
                                    attrs: {
                                      "class-name": "arrow-down-icon",
                                      "icon-class": "arrow-up",
                                    },
                                  }),
                                ],
                                1
                              )
                            : scope.row.rank_diff1 != null &&
                              scope.row.rank_diff1.indexOf("-") == 0 &&
                              scope.row.rank_diff1.indexOf("--") != 0
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(scope.row.rank_diff1.substr(1)) +
                                      "\n          "
                                  ),
                                  _c("svg-icon", {
                                    attrs: {
                                      "class-name": "arrow-down-icon",
                                      "icon-class": "arrow-down",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(scope.row.rank_diff1) +
                                    "\n        "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1761379139
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.header.filter(function (n) {
                    return n.code == "shop0"
                  })[0].label,
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n        " + _vm._s(scope.row.shop0) + "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3736017001
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.header.filter(function (n) {
                    return n.code == "rank_diff0"
                  })[0].label,
                  width: "50",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.rank_diff0 != null &&
                          scope.row.rank_diff0.indexOf("+") == 0
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(scope.row.rank_diff0.substr(1)) +
                                      "\n          "
                                  ),
                                  _c("svg-icon", {
                                    attrs: {
                                      "class-name": "arrow-down-icon",
                                      "icon-class": "arrow-up",
                                    },
                                  }),
                                ],
                                1
                              )
                            : scope.row.rank_diff0 != null &&
                              scope.row.rank_diff0.indexOf("-") == 0 &&
                              scope.row.rank_diff0.indexOf("--") != 0
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(scope.row.rank_diff0.substr(1)) +
                                      "\n          "
                                  ),
                                  _c("svg-icon", {
                                    attrs: {
                                      "class-name": "arrow-down-icon",
                                      "icon-class": "arrow-down",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(scope.row.rank_diff0) +
                                    "\n        "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3539792291
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }