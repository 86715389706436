//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PanelGroup from "./components/PanelGroup";
import StockDaysBarChart from "./components/StockDaysBarChart";
import ProductRankTable from "./components/ProductRankTable";
import ShopRankTable from "./components/ShopRankTable";
import ReturnGoodsLineChart from "./components/ReturnGoodsLineChart";
export default {
  name: 'AdminDashboard',
  components: {
    PanelGroup: PanelGroup,
    StockDaysBarChart: StockDaysBarChart,
    ProductRankTable: ProductRankTable,
    ShopRankTable: ShopRankTable,
    ReturnGoodsLineChart: ReturnGoodsLineChart
  },
  data: function data() {
    return {
      reportRequest: {
        language: this.$store.getters.language
      }
    };
  },
  watch: {
    '$route': function $route(to, from) {
      if (to.path == '/dashboard') {
        this.refreshChart();
      }
    }
  },
  mounted: function mounted() {
    this.refreshChart();
  },
  methods: {
    refreshChart: function refreshChart() {
      this.reportRequest.language = this.$store.getters.language;
      this.$refs.stockDaysBarChart.refreshChart(this.reportRequest);
      this.$refs.productRankTable.refreshTable(this.reportRequest);
      this.$refs.shopRankTable.refreshTable(this.reportRequest);
      this.$refs.returnGoodsLineChart.refreshChart(this.reportRequest);
    }
  }
};