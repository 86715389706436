//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { shopRank } from '@/api/dashboard';
export default {
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        success: 'success',
        pending: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      header: [],
      listData: []
    };
  },
  created: function created() {},
  methods: {
    refreshTable: function refreshTable(reportRequest) {
      // console.log(reportRequest)
      // this.chart.clear()
      this.reportRequest = reportRequest;
      this.getShopRank();
      //this.chart.setOption(option)
    },
    getShopRank: function getShopRank() {
      var _this = this;
      return new Promise(function (resolve, reject) {
        shopRank(_this.reportRequest).then(function (response) {
          //console.log(response.data)
          if (response.data.code !== '0') {
            _this.$notify({
              title: _this.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          _this.header = response.data.header;
          _this.listData = response.data.data;
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    iRowStyle: function iRowStyle(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      return 'height:35px';
    },
    iHeaderRowStyle: function iHeaderRowStyle(_ref2) {
      var row = _ref2.row,
        rowIndex = _ref2.rowIndex;
      return 'height:35px';
    },
    iCellStyle: function iCellStyle(_ref3) {
      var row = _ref3.row,
        column = _ref3.column,
        rowIndex = _ref3.rowIndex,
        columnIndex = _ref3.columnIndex;
      return 'padding:0px';
    },
    iHeaderCellStyle: function iHeaderCellStyle(_ref4) {
      var row = _ref4.row,
        column = _ref4.column,
        rowIndex = _ref4.rowIndex,
        columnIndex = _ref4.columnIndex;
      return 'padding:0px;background:#6099c4;color:#fff';
    }
  }
};