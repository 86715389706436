//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CountTo from 'vue-count-to';
import { overview } from '@/api/uploadmonitor';
import { pssOverview } from '@/api/dashboard';
import GaugeChart from "./GaugeChart";
export default {
  components: {
    CountTo: CountTo,
    GaugeChart: GaugeChart
  },
  data: function data() {
    return {
      num1: 0,
      num2: 0,
      pssOverview: {
        purchase_amount_mtd: 0,
        purchase_amount_target: 0,
        purchase_amount_achieve_percentage: 0,
        purchase_amount_rate_yoy: 0,
        purchase_amount_rate_mom: 0,
        sales_amount_mtd: 0,
        sales_amount_rate_yoy: 0,
        sales_amount_rate_mom: 0,
        stock_quantity: 0,
        stock_amount: 0,
        stock_days: 0,
        stock_amount_rate_yoy: 0,
        stock_amount_rate_mom: 0
      },
      reportRequest: {
        language: this.$store.getters.language
      }
    };
  },
  computed: {
    item1: function item1() {
      return this.$t('dashboard.card_panel.item1');
    },
    item2: function item2() {
      return this.$t('dashboard.card_panel.item2');
    },
    item3: function item3() {
      return this.$t('dashboard.card_panel.item3');
    },
    item4: function item4() {
      return this.$t('dashboard.card_panel.item4');
    }
  },
  watch: {
    '$route': function $route(to, from) {
      if (to.path == '/dashboard') {
        //this.getUploadData()
        //this.getPssOverview()
      }
    }
  },
  created: function created() {
    this.getUploadData();
    this.getPssOverview();
  },
  methods: {
    getUploadData: function getUploadData() {
      var _this = this;
      return new Promise(function (resolve, reject) {
        overview('').then(function (response) {
          // console.log(response)
          if (response.data.code !== '0') {
            _this.$notify({
              title: _this.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          var _response$data$data = response.data.data,
            num1 = _response$data$data.num1,
            num2 = _response$data$data.num2;
          _this.num1 = num1;
          _this.num2 = num2;
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    getPssOverview: function getPssOverview() {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        pssOverview(_this2.reportRequest).then(function (response) {
          // console.log(response)
          if (response.data.code !== '0') {
            _this2.$notify({
              title: _this2.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          var dataArr = response.data.data;
          if (dataArr && dataArr.length > 0) {
            _this2.pssOverview = dataArr[0];
            _this2.$refs.purchaseGaugeChart.refreshChart([{
              value: _this2.pssOverview.purchase_amount_achieve_percentage,
              name: ''
            }]);
            _this2.$refs.salesGaugeChart.refreshChart([{
              value: 0,
              name: ''
            }]);
          }
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    goUploadMonitor: function goUploadMonitor() {
      this.$router.push('/uploadmonitor');
    }
  }
};