import request from '@/utils/request';
export function distributorOverview(data) {
  return request({
    url: '/client-portal-upload-batch/distributor-overview',
    method: 'post',
    data: data
  });
}
export function batchList(data) {
  return request({
    url: '/client-portal-upload-batch/batch-list',
    method: 'post',
    data: data
  });
}
export function confirmUpload(uploadType, uploadScheduleId, periodId, data) {
  return request({
    url: '/client-portal-upload-batch/confirm-upload',
    method: 'post',
    params: {
      uploadType: uploadType,
      uploadScheduleId: uploadScheduleId,
      periodId: periodId
    },
    data: data
  });
}
export function getLastUploadBatch(uploadScheduleId) {
  return request({
    url: '/client-portal-upload-batch/get-last-upload-batch',
    method: 'post',
    params: {
      uploadScheduleId: uploadScheduleId
    }
  });
}