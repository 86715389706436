import "core-js/modules/es7.array.includes";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
//
//
//
//

import echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from "./mixins/resize";
import { returnGoods } from '@/api/dashboard';
var animationDuration = 6000;
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data: function data() {
    return {
      chart: null,
      option: {
        title: {
          text: this.$t('dashboard.return_goods_line_chart.product_return_trend')
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'line'
          }
        },
        grid: {
          top: 40,
          left: '2%',
          right: '5%',
          bottom: '3%',
          containLabel: true
        },
        legend: {
          data: ['Brand']
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Month']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: 'Brand',
          type: 'line',
          data: [0],
          animationDuration: animationDuration
        }]
      }
    };
  },
  computed: {
    language: function language() {
      return this.$store.getters.language;
    }
  },
  watch: {
    language: function language(value) {
      //console.log(value)
      this.option.title.text = this.$t('dashboard.return_goods_line_chart.product_return_trend');
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  created: function created() {},
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.chart.setOption(this.option);
    },
    refreshChart: function refreshChart(reportRequest) {
      this.reportRequest = reportRequest;
      this.getReturnGoods();
    },
    getReturnGoods: function getReturnGoods() {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        returnGoods(_this2.reportRequest).then(function (response) {
          //console.log(response.data)
          if (response.data.code !== '0') {
            _this2.$notify({
              title: _this2.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          var chartData = response.data.data;
          if (chartData && chartData.length > 0) {
            var legendData = [];
            var xAxisData = [];
            var series = [];
            var _iterator = _createForOfIteratorHelper(chartData),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var _item = _step.value;
                if (!legendData.includes(_item.brand)) {
                  legendData.push(_item.brand);
                }
                if (!xAxisData.includes(_item.month)) {
                  xAxisData.push(_item.month);
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            for (var _i = 0, _legendData = legendData; _i < _legendData.length; _i++) {
              var name = _legendData[_i];
              var seriesItem = {
                name: name,
                type: 'line',
                data: [],
                animationDuration: animationDuration
              };
              var _iterator2 = _createForOfIteratorHelper(chartData),
                _step2;
              try {
                for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                  var item = _step2.value;
                  if (item.brand == name) {
                    seriesItem.data.push(item.amount);
                  }
                }
              } catch (err) {
                _iterator2.e(err);
              } finally {
                _iterator2.f();
              }
              series.push(seriesItem);
            }
            _this2.option.legend.data = legendData;
            _this2.option.xAxis.data = xAxisData;
            _this2.option.series = series;
          } else {
            _this2.option.legend.data = [];
            _this2.option.xAxis.data = [];
            _this2.option.series = [];
          }
          if (_this2.chart) {
            _this2.chart.clear();
            _this2.chart.setOption(_this2.option);
          }
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  }
};